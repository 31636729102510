import * as types from "./types";
/* eslint-disable-next-line no-unused-vars */

export const show =
  (message, props, dismissTimeout = 3000) =>
  (dispatch) => {
    dispatch({
      type: types.SHOW,
      payload: {
        message,
        props,
      },
    });
    if (dismissTimeout)
      setTimeout(() => dispatch(dismiss(message)), dismissTimeout);
  };

export const dismiss = (message) => ({ type: types.DISMISS, payload: message });
