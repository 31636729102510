import * as types from "./types";

export const start = (name, params) => ({
  type: types.START,
  payload: {
    action: {
      name,
      params,
    },
  },
});

export const stop = (name) => ({
  type: types.STOP,
  payload: { name },
});

export const refreshStart = (refreshAction) => ({
  type: types.REFRESH_START,
  payload: { refreshAction },
});

export const refreshStop = (refreshAction) => ({
  type: types.REFRESH_STOP,
  payload: { refreshAction },
});
