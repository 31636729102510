import React, { Component } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { modalOperations } from "src/state/ducks/modal";

class ModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstConfirmation: false,
      askForConfirmationToCancel: false,
    };
  }

  handleCancel = () => {
    const { onCancel, closeModal, askForConfirmationToCancel } = this.props;
    if (
      askForConfirmationToCancel &&
      !window.confirm(
        "Deseja fechar a janela? Os dados preenchidos serão perdidos."
      )
    ) {
      return;
    }

    closeModal();
    onCancel && onCancel();
    this.setState({ firstConfirmation: false });
  };

  handleConfirmation = () => {
    const {
      onConfirm,
      doubleClickToConfirm, // TODO: Maybe use another color for this cases, maybe red
      closeModal,
      closeOnConfirmation = true,
    } = this.props;
    const { firstConfirmation } = this.state;

    if (doubleClickToConfirm && !firstConfirmation) {
      this.setState({ firstConfirmation: true });
      return;
    }

    closeOnConfirmation && closeModal();
    onConfirm && onConfirm({ state: { ...this.state }, close: closeModal });
    this.setState({ firstConfirmation: false });
  };

  render() {
    const {
      title,
      body,
      size,
      closeModal,
      className,
      onConfirm,
      onCancel,
      cancelButtonLabel,
      confirmButtonLabel,
      doubleClickToConfirm,
      confirmSecondLabel,
      isOpen,
      closeOnBackdrop = false,
    } = this.props;
    const { firstConfirmation } = this.state;

    const confirmLabel = confirmButtonLabel || "Confirmar";
    const confirmSecLabel = confirmSecondLabel || "Clique novamente";
    const footerContent = [
      onCancel !== null && (
        <>
          <CButton
            color="secondary"
            variant="outline"
            onClick={this.handleCancel}
          >
            {cancelButtonLabel || "Cancelar"}
          </CButton>
        </>
      ),
      onConfirm !== null && (
        <>
          <CButton onClick={this.handleConfirmation} color="primary">
            {doubleClickToConfirm
              ? firstConfirmation
                ? confirmSecLabel
                : confirmLabel
              : confirmLabel}
          </CButton>
        </>
      ),
    ].filter(Boolean);

    const Body = body;

    return (
      <CModal
        show={isOpen}
        size={size || ""}
        className={className}
        closeOnBackdrop={closeOnBackdrop}
        onClosed={() => {
          if (isOpen) {
            closeModal();
          }
        }}
      >
        <CModalHeader closeButton>
          <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {typeof body === "function" ? (
            <Body
              setState={(newValue) => this.setState(newValue)}
              state={{ ...this.state }}
            />
          ) : (
            body
          )}
        </CModalBody>

        <CModalFooter>{footerContent}</CModalFooter>
      </CModal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ closeModal: modalOperations.dismiss }, dispatch);
};

export default connect(null, mapDispatchToProps)(ModalComponent);
