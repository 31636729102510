import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { icons } from "./assets/icons";
import { Provider } from "react-redux";
import store from "./store";
import { serviceWorkerOperations } from "./state/ducks/serviceWorker";
require("dotenv").config();

React.icons = icons;

if (
  process.env.REACT_APP_SENTRY_ENABLE &&
  JSON.parse(process.env.REACT_APP_SENTRY_ENABLE) &&
  !!process.env.REACT_APP_SENTRY_DSN &&
  process.env.NODE_ENV !== "development"
)
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
    environment: process.env.NODE_ENV,
  });

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.register({
  onSuccess: (reg) => serviceWorkerOperations.init(reg)(store.dispatch),
  onUpdate: (reg) => serviceWorkerOperations.update(reg)(store.dispatch),
});
