import * as types from "./types";
/* eslint-disable-next-line no-unused-vars */

export const init = () => (dispatch) =>
  dispatch({
    type: types.INIT,
  });

export const update = (reg) => (dispatch) =>
  dispatch({ type: types.UPDATE, payload: reg });

export const reload = () => (dispatch) => dispatch({ type: types.RELOAD });
