import * as types from "./types";
/* eslint-disable-next-line no-unused-vars */

export const show = (props) => ({
  type: types.SHOW,
  payload: {
    props,
  },
});

export const dismiss = () => ({
  type: types.DISMISS,
});
