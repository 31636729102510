import { combineReducers } from "redux";

import { default as toast } from "./ducks/toast";
import { default as modal } from "./ducks/modal";
import { default as loader } from "./ducks/loader";
import { default as sidebar } from "./ducks/sidebar";
import { default as serviceWorker } from "./ducks/serviceWorker";

export default combineReducers({
  toast,
  modal,
  loader,
  sidebar,
  serviceWorker,
});
