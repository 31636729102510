import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { DefaultToast } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import "./scss/style.scss";
import { toastOperations } from "./state/ducks/toast";
import ActivityIndicator from "./reusable/ActivityIndicator";
import { loaderSelectors } from "./state/ducks/loader";
import { useReloadSW } from "./state/ducks/serviceWorker/hooks";
import {
  CAlert,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react";
import ModalComponent from "./reusable/ModalComponent";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

const loadingIndicator = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const ServiceWorkerUpdater = () => {
  const [updateDismissed, setUpdatedDismissed] = useState(false);

  const realoadSw = useReloadSW();
  const { updated: serviceWorkerUpdated } = useSelector(
    ({ serviceWorker }) => serviceWorker
  );

  return (
    <>
      {serviceWorkerUpdated && !updateDismissed && (
        <CModal show>
          <CModalHeader>Atualização da aplicação</CModalHeader>
          <CModalBody>
            <CAlert color="info">Existe uma nova versão disponível!</CAlert>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => setUpdatedDismissed(true)}
            >
              Cancelar
            </CButton>
            <CButton
              color="primary"
              onClick={() => {
                setUpdatedDismissed(true);
                realoadSw();
              }}
            >
              Atualizar
            </CButton>
          </CModalFooter>
        </CModal>
      )}
    </>
  );
};

const App = () => {
  const toast = useSelector((s) => s.toast);
  const loading = useSelector((s) => loaderSelectors.checkIfAnyLoading(s));

  const { props: modalProps, isOpen: isModalOpen } = useSelector(
    (s) => s.modal
  );

  const dispatch = useDispatch();
  const dismissToast = (...params) =>
    dispatch(toastOperations.dismiss(...params));

  return (
    <BrowserRouter>
      <React.Suspense fallback={loadingIndicator}>
        <MuiPickersUtilsProvider locale={"pt-br"} utils={MomentUtils}>
          {!!loading && <ActivityIndicator />}
          <ServiceWorkerUpdater />
          {isModalOpen && <ModalComponent isOpen {...modalProps} />}
          <div className="toast-container">
            {(toast || []).map((t, idx) => (
              <DefaultToast
                key={idx}
                appearance="info"
                placement="top-right"
                onDismiss={() => dismissToast(t.message)}
                {...(t.props || {})}
              >
                {t.message}
              </DefaultToast>
            ))}
          </div>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Dashboard"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </MuiPickersUtilsProvider>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
