import React from "react";
import "./ActivityIndicator.scss";

const ActivityIndicator = () => {
  return (
    <div className="activity-indicator-container">
      <div className="loader">Loading...</div>
    </div>
  );
};

export default ActivityIndicator;
