import * as types from "./types";

const reducer = (state = { isOpen: false }, { type, payload }) => {
  switch (type) {
    case types.SHOW:
      return {
        ...payload,
        isOpen: true,
      };

    case types.DISMISS:
      return { isOpen: false };

    default:
      return state;
  }
};

export default reducer;
